import React from 'react'

export const Servicios_Form_1 = () => {
    return (
        <div className="container-fluid g-0">
            <div className="servicios_box">
                <div className="servicio_sp">
                    <span className="span_title_services">
                        Sportbook
                    </span>
                </div>
            </div>
            <div className="servicios_box">
                <div className="live_casino">
                    <span className="span_title_services">
                        Live Casino
                    </span>
                </div>
            </div>
            <div className="servicios_box">
                <div className="online_casino">
                    <span className="span_title_services">
                        Online Casino
                    </span>
                </div>
            </div>
            <div className="servicios_box">
                <div className="live_betting">
                    <span className="span_title_services">
                        Live Betting
                    </span>
                </div>
            </div>
            <div className="servicios_box">
                <div className="races">
                    <span className="span_title_services">
                        Horses
                    </span>
                </div>
            </div>
            <div className="servicios_box">
                <div className="poker">
                    <span className="span_title_services">
                        Poker
                    </span>
                </div>
            </div>
            <div className="servicios_box">
                <div className="prop_builder">
                    <span className="span_title_services">
                        Prop Builder
                    </span>
                </div>
            </div>
            <div className="servicios_box">
                <div className="call_center">
                    <span className="span_title_services">
                        Call Center
                    </span>
                </div>
            </div>
        </div>
    )
}
