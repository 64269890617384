import React from 'react';
import { Slider } from '../components/slider';
// import { Scroll_Butons } from '../components/scroll_butons';
// import { Tabs_Sports } from '../components/tabs_sports.js';
// import { Resultados } from '../components/resultados';
import { Servicios_Form_1 } from '../components/servicios_form_1';
import { Noticias_Form_1 } from '../components/noticias_form_1';
export const Home = () => {
    return (
        <div>
            {/* <Resultados/> */}
            <Slider />
            {/* <Scroll_Butons />
            <Tabs_Sports /> */}
            <Servicios_Form_1/>
            <Noticias_Form_1/>
        </div>
    )
}
